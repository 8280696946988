import React, { ReactElement, FC, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RegistrationFlow } from '@ory/client'
import { AxiosResponse } from 'axios'
import { newKratosSdk } from '../services/kratos'
import { AuthContext } from '../components/AuthProvider'
import RegisterForm from '../components/RegisterForm'
import Layout, { MediumContainer } from '../Layout'
import { useBannerUpdate } from '../helpers/useBannerUpdate'
import { csrfFromUiContainer } from '../helpers/kratos'
import { guardSession, SessionCheck, SessionStateGuard } from '../helpers/sessionGuard'

const Register: FC<any> = (): ReactElement => {
    const { getSession, session } = useContext(AuthContext)
    const [sessionCheck, setSessionCheck] = useState<SessionCheck>(SessionCheck.Unverified)

    const navigate = useNavigate()
    const [flow, setFlow] = useState<RegistrationFlow | undefined>(undefined)
    const [banner, setBanner] = useBannerUpdate()

    const [csrfToken, setCsrfToken] = useState<string>('')

    const initializeRegistrationFlow = () => {
        const verificationReturnTo = window.location.href.replace('/register', '/login')
        newKratosSdk()
            .createBrowserRegistrationFlow({
                afterVerificationReturnTo: verificationReturnTo,
            })
            .then((response: AxiosResponse<RegistrationFlow>) => {
                const { data: flow } = response
                setFlow(flow)
                setCsrfToken(csrfFromUiContainer(flow.ui))
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    const body = err.response.data
                    if (body?.error?.id === 'session_already_available') {
                        // In this case, we're already logged in, so we sync state and then navigate home
                        getSession().then(() => navigate('/'))
                    }
                }
                console.error(err)
            })
    }

    useEffect(
        () =>
            guardSession(session, {
                active: SessionStateGuard.InactiveOnly,
                onMatch: () => {
                    // There is no valid session, so we may proceed with initializing registration
                    setSessionCheck(SessionCheck.Valid)
                },
                onNoMatch: () => {
                    // There is already a valid session, so we don't want to initialize registration
                    // so we redirect to home
                    setSessionCheck(SessionCheck.Invalid)
                    navigate('/')
                },
            }),
        [session]
    )

    useEffect(() => {
        console.debug('[useEffect(sessionCheck)] sessionCheck changed...', sessionCheck)
        if (sessionCheck !== SessionCheck.Valid) {
            return
        }

        initializeRegistrationFlow()

        return () => {
            setFlow(undefined)
            setCsrfToken('')
        }
    }, [sessionCheck])

    return (
        <Layout heading="Sign Up" banner={banner}>
            <MediumContainer>
                <RegisterForm flow={flow} csrfToken={csrfToken} setBanner={setBanner} />
            </MediumContainer>
        </Layout>
    )
}

export default Register
