import { BASE_DOMAIN, RETURN_TO_URLS } from '../config'
import { urlIsSafe } from './safelyNavigate'

const PARSED_RETURN_TO_URLS = RETURN_TO_URLS?.split(',').flatMap((url) => {
    const parsedUrl = parseURL(url)
    // Remove unparsable URLs
    return parsedUrl ? [parsedUrl] : []
})

function parseURL(url: string): URL | null {
    try {
        return new URL(url)
    } catch (err) {
        console.warn(`got error while parsing return_to parameter: ${err}`)
        return null
    }
}

export default function getReturnTo(
    params: URLSearchParams,
    defaultReturnTo: URL | null = null
): URL | null {
    // If not return_to param was provided, just go to the search app
    const returnToParam = params.get('return_to')
    if (!returnToParam) {
        return defaultReturnTo
    }

    // Parse the return_to address, returning the default if parsing fails
    const parsedUrl = parseURL(returnToParam)
    if (parsedUrl === null) {
        return defaultReturnTo
    }

    // Return the default if the url protocol is not safe (http/https)
    if (!urlIsSafe(parsedUrl)) {
        return defaultReturnTo
    }

    // Return the default if the url hostname does not match the censys domain
    if (!parsedUrl.host.endsWith(BASE_DOMAIN)) {
        return defaultReturnTo
    }

    return parsedUrl
}

export function parseAndValidateReturnTo(returnTo: string | null): URL | null {
    if (returnTo === null) {
        return null
    }

    const returnUrl = parseURL(returnTo)
    if (returnUrl === null) {
        return null
    }

    if (!isValidRedirect(returnUrl)) {
        console.warn(`invalid return_to URL: ${returnTo}`)
        return null
    }

    return returnUrl
}

export function isValidRedirect(url: URL): boolean {
    // Support for return_to URLs under allowed (wildcard) domains
    if (PARSED_RETURN_TO_URLS !== undefined) {
        for (const allowedUrl of PARSED_RETURN_TO_URLS) {
            if (url.protocol !== allowedUrl.protocol) {
                continue
            }
            // URL decode the strings to avoid issues with URL encoding
            const dUrlHostname = decodeURIComponent(url.hostname)
            const dAllowedHostname = decodeURIComponent(allowedUrl.hostname)
            if (dAllowedHostname.startsWith('*')) {
                const allowedHostnameSuffix = removePrefix(removePrefix(dAllowedHostname, '*'), '.')
                if (dUrlHostname.endsWith(allowedHostnameSuffix)) {
                    const urlPrefix = removeSuffix(dUrlHostname, allowedHostnameSuffix)
                    if (urlPrefix === '' || urlPrefix.endsWith('.')) {
                        return true
                    }
                }
            } else if (dUrlHostname === dAllowedHostname) {
                return true
            }
        }
    }
    return false
}

function removePrefix(inputString: string, prefix: string): string {
    if (inputString.startsWith(prefix)) {
        return inputString.slice(prefix.length)
    }
    return inputString // If the prefix is not found, return the original string
}

function removeSuffix(inputString: string, suffix: string): string {
    if (inputString.endsWith(suffix)) {
        return inputString.slice(0, -suffix.length)
    }
    return inputString // If the suffix is not found, return the original string
}
