// This should be kept in sync with the config on kratos. Helps us
// determine when we should make the user refresh their session.
// Age is in seconds
export const PRIVILEGED_SESSION_MAX_AGE: number = parseInt(
    process.env.REACT_APP_PRIVILEGED_SESSION_MAX_AGE || '3600'
)

// Hcaptcha site key, defaulting to blank
export const HCAPTCHA_SITE_KEY: string = process.env.REACT_APP_HCAPTCHA_SITE_KEY ?? ''

// Environment label to use for the Environment badge.
// WARNING: This should not be used as a feature flag
export const ENVIRONMENT_LABEL: string | undefined = process.env.REACT_APP_ENVIRONMENT

// The domain name for the ASM site
// Undefined behavior if this env var is not set
export const ASM_DOMAIN: string = process.env.REACT_APP_ASM_DOMAIN!

// The domain name for the Search site
// Undefined behavior if this env var is not set
export const SEARCH_DOMAIN: string = process.env.REACT_APP_SEARCH_DOMAIN!

// The domain name for the CTF site
// Undefined behavior if this env var is not set
export const CTF_DOMAIN: string = process.env.REACT_APP_CTF_DOMAIN!

// The domain used for verifying return_to addresses. The hostname for all return_to
// addresses will be required to end with this value.
export const BASE_DOMAIN: string = process.env.REACT_APP_BASE_DOMAIN!

// A comma-separated list of URLs that are allowed to be used as return_to URLs.
// Wildcards are allowed, but only as the left-most part of the domain.
export const RETURN_TO_URLS: string | undefined = process.env.REACT_APP_RETURN_TO_URLS

export const LAUNCHDARKLY_CLIENT_ID: string = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID!

export const PENDO_API_KEY: string | undefined = process.env.REACT_APP_PENDO_KEY
